import axios from "axios";
import router from "@/router";
import { message } from "ant-design-vue";
import NProgress from "nprogress";
import { Base64 } from "js-base64";

const apiHost = process.env.VUE_APP_API_HOST;
const baseURL = `${apiHost}/api/blue`;

// 附件基础路径
const imageBaseUrl = `${process.env.VUE_APP_IMG_HOST}/`;
// 文件预览服务路径
const previewBaseUrl = "http://jpintech.tpddns.cn:8012/onlinePreview?url=";

const apiClient = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const downloadApiClient = axios.create({
  baseURL,
  withCredentials: false,
  responseType: "blob"
});

downloadApiClient.interceptors.request.use(config => {
  NProgress.start();
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// 如果服务端返回401,则跳转到登录页面
downloadApiClient.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  error => {
    NProgress.done();
    if (error.response.status === 401) {
      router.push({ name: "Login" });
    } else {
      message.error("系统繁忙，请重试");
      return Promise.reject(error);
    }
  }
);

// 在请求发出前Header中加上 token
apiClient.interceptors.request.use(config => {
  NProgress.start();
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// 如果服务端返回401,则跳转到登录页面
apiClient.interceptors.response.use(
  response => {
    if (response.data.code === "FAILED") {
      message.error(response.data.errorMsg);
    }
    NProgress.done();
    return response;
  },
  error => {
    NProgress.done();
    if (error.response.status === 401) {
      message.warn("需要您登录后操作，即将跳转到登录页面...");
      setTimeout(() => {
        router.push({ name: "Login" });
      }, 3000);
    } else {
      message.error("网络错误，请重试");
      return Promise.reject(error);
    }
  }
);

export { apiClient, downloadApiClient, baseURL, imageBaseUrl };

export function genDownloadUrl(relativePath) {
  return imageBaseUrl + relativePath;
}

export function preview(url) {
  return previewBaseUrl + encodeURIComponent(Base64.encode(url));
}
